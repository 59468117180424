import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const DropdownComponent = ({
  data,
  selectedConsultationType,
  setSelectedCelectedConsultationType,
  setSelectedConsultationMode,
  handleMode,
  setReload,
  setIsActiveType
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const handleConsultationMode = (subItemId, subItemLabel) => {
    setSelectedItem(subItemId);
    setShowSubMenu(false);
    setIsDropdownOpen(false);
    setSelectedConsultationMode(subItemLabel);
    setReload(true);
    handleMode();
    setIsActiveType("OP")
  };

  const handleMainMenuClick = (select) => {
    setSelectedCelectedConsultationType(select);
    handleMode();
    setReload(true);
    setIsActiveType(null)
  };

  return (
    <div
      style={{
        height: "auto",
        boxShadow: `rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px`,
        padding: 10,
        position: "relative",
        backgroundColor: "white",
       
      }}
    >
      <div
        style={{
          width: "100%",
          height: 40,
          overflow: "hidden",
          border: "1px solid #06c6a2",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          setIsDropdownOpen((prev) => !prev);
          setShowSubMenu(false);
        }}
      >
        <input
          style={{
            width: "88%",
            height: 36,
            paddingLeft: 10,
            outline: "none",
            border: "none",
          }}
          value={selectedConsultationType}
          readOnly
          placeholder="Select"
        />
        <KeyboardArrowDownIcon
          style={{
            marginRight: "5px",
            cursor: "pointer",
            transform: isDropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        />
      </div>
      {isDropdownOpen && (
        <div style={{ width: "100%", marginTop: "5px" }}>
          {data.map((item) => {
            return (
              <div style={{ display: "flex" }} key={item.id}>
                <div
                  key={item.id}
                  style={{
                    display: "flex",
                    backgroundColor: "#F7F7F7",
                    alignItems: "center",
                    height: 36,
                    justifyContent: "space-between",
                    cursor: "pointer",
                    marginTop: item.id !== 1 ? "1px" : "0",
                    paddingRight: "10px",
                    width: "85%",
                  }}
                  onClick={() => {
                    handleMainMenuClick(item.label);
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#e0f7fa")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#F7F7F7")
                  }
                >
                  <p style={{ margin: 0, paddingLeft: 10, fontWeight: 600 }}>
                    {item.label}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#F7F7F7",
                    alignItems: "center",
                    height: 36,
                    width: "15%",
                  }}
                >
                  <KeyboardDoubleArrowRightIcon
                    sx={{
                      color:
                        item?.consultationType?.length > 0
                          ? "#1B5A7A"
                          : "#E0E7F1",
                      transform:
                        showSubMenu === item?.consultationType?.length > 0
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                    }}
                    onClick={() => {
                      item.consultationType && setShowSubMenu((prev) => !prev);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}

      {showSubMenu && (
        <div
          style={{
            width: "230px",
            marginTop: "5px",
            position: "absolute",
            backgroundColor: "white",
            top: "90px",
            right: -230,
            boxShadow: `rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px`,
            padding: "10px",
            zIndex: 1,
          }}
        >
          {data
            .find((item) => item.consultationType)
            ?.consultationType.map((subItem) => (
              <div
                key={subItem.id}
                style={{
                  display: "flex",
                  backgroundColor: "#F7F7F7",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: 40,
                  cursor: "pointer",
                  marginTop: subItem.id !== 1 ? "1px" : "0",
                  transition: "background-color 0.3s ease",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#e0f7fa")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#F7F7F7")
                }
                onClick={()=>handleConsultationMode(subItem.id, subItem.label)}
              >
                <p
                  style={{
                    margin: 0,
                    paddingLeft: 10,
                    padding: "0px 12px",
                    color: "black",
                    fontWeight: 600,
                    borderRadius: "0px",
                    fontSize: "14px",
                  }}
                >
                  {subItem.label}
                </p>
              
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default DropdownComponent;
